import React from 'react';
import { useTranslation } from 'react-i18next';
import MonaSide from '../../ui/assets/monaSide.webp';
import RoundImage from '../../components/roundImage';
import { ReactComponent as Circle } from '../../ui/assets/circle.svg';
import { ReactComponent as HalfCircle } from '../../ui/assets/halfCircle.svg';

export default function OrderConfirmation() {
  const { t } = useTranslation();

  return (
    <div className="layout-container">
      <div className="order-confirmation">
        <div className="order-confirmation__icon-container">
          <div className="order-confirmation__icon-container__icon order-confirmation__icon-container__icon  order-confirmation__icon-container__icon--end">
            <HalfCircle />
          </div>
          <div className="order-confirmation__icon-container__icon order-confirmation__icon-container__icon">
            <Circle />
          </div>
        </div>
        <div className="mona-image-and-text">
          <div>
            <div>
              <RoundImage image={MonaSide} />
            </div>
          </div>
          <h3>{t('pages.orderConfirmation.title')}</h3>
          <p>{t('pages.orderConfirmation.subtitle')}</p>
        </div>
      </div>
    </div>
  );
}
