import React from 'react';
import i18n from '../i18n';

export default function LanguageSwitcher() {
  return (
    <div className="language-switcher">
      <select
        onChange={(e) => {
          i18n.changeLanguage(e.target.value);
        }}
        value={i18n.language}
      >
        <option value="en">EN</option>
        <option value="fr">FR</option>
        <option value="nl">NL</option>
      </select>
    </div>
  );
}
