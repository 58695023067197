import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import getRoles from './api/user';
import LoginPage from './pages/login-page/loginPage';
import ProjectDetails from './pages/project-details/projectDetails';
import ProjectOverviewClient from './pages/project-overview-client/projectOverviewClient';
import ProjectSteps from './pages/project-steps/projectSteps';

function App() {
  const [wizardStep, setWizardStep] = useState(0);
  const { isAuthenticated, getAccessTokenSilently, isLoading, logout, user } = useAuth0();

  const handleChangeStep = (step) => {
    setWizardStep(step);
  };

  useEffect(() => {
    const getRolesFromBackend = async () => {
      try {
        const token = await getAccessTokenSilently();
        const updatedRoles = await getRoles(token, user.email);
        if (updatedRoles) {
          // updateIsDesiger(updatedRoles.includes('Designer'));
          // updateIsClient(updatedRoles.includes('Client'));
        }
      } catch (e) {
        console.error('Error while fetching roles', e);
      }
    };
    if (isAuthenticated) {
      getRolesFromBackend();
    }
  }, [isAuthenticated]);

  return (
    <div className="app">
      <Router>
        <Routes>
          {!isAuthenticated && !isLoading && !user && (
            <Route path="/projects" element={<LoginPage />} />
          )}
          {isAuthenticated && user && (
            <>
              <Route path="/projects" element={<ProjectOverviewClient />} />
              <Route path="/project/:id" element={<ProjectDetails />} />
            </>
          )}
          <Route
            path="/"
            element={<ProjectSteps wizardStep={wizardStep} changeWizardStep={handleChangeStep} />}
          />
          <Route
            path="/new-project"
            element={<ProjectSteps wizardStep={wizardStep} changeWizardStep={handleChangeStep} />}
          />
          <Route
            path="/new-project/:id"
            element={<ProjectSteps wizardStep={wizardStep} changeWizardStep={handleChangeStep} />}
          />
        </Routes>
      </Router>
      {isAuthenticated && (
        <div
          onClick={() => {
            logout({ logoutParams: { returnTo: window.location.origin } });
          }}
        >
          {`Logout user ${user?.name}`}
        </div>
      )}
    </div>
  );
}

export default App;
