import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getResource } from '../../api/resource';
import Sidebar from '../../components/sidebar';

export default function ProjectDetails() {
  const [project, setProject] = useState({});
  const [room, setRoom] = useState({});
  //   const [selectedColors, setSelectedColors] = useState([]);
  //   const [selectedStyles, setSelectedStyles] = useState([]);
  const [selectedBudget, setSelectedBudget] = useState({});
  const [selectedSchedule, setSelectedSchedule] = useState({});
  const [client, setClient] = useState({});
  const [designPackage, setDesignPackage] = useState({});
  //   const [picturesCurrentRoom, setPicturesCurrentRoom] = useState([]);
  //   const [uploadedStyleImages, setUploadedStyleImages] = useState([]);

  const { getAccessTokenSilently } = useAuth0();
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      (async () => {
        const token = await getAccessTokenSilently();
        const projectResource = await getResource(
          `${process.env.REACT_APP_BACKEND_URL}/projects/${id}`,
          token,
        );
        if (!projectResource) {
          navigate('/');
        }
        const roomResource = await getResource(projectResource._links.room.href, token);
        const clientResource = await getResource(projectResource._links.client.href, token);
        const designPackageResource = await getResource(
          projectResource._links.designPackage.href,
          token,
        );
        setProject(projectResource);
        setSelectedBudget({ id: projectResource.budget });
        setSelectedSchedule({ id: projectResource.schedule });
        setRoom(roomResource);
        setClient(clientResource);
        setDesignPackage(designPackageResource);

        console.log('Project:', project);
        console.log('Room:', room);
        console.log('Client:', client);
        console.log('Design Package:', designPackage);
        console.log('Budget:', selectedBudget);
        console.log('Schedule:', selectedSchedule);
      })();
    } else {
      navigate('/');
    }
  }, []);

  //   const getColors = async () => {
  //     const colors = await getResource(project._links.colors.href);
  //     setSelectedColors(colors._embedded?.colors);
  //     colorSelection.current = {
  //       existing: colors._embedded?.colors,
  //       new: [],
  //       toDelete: [],
  //     };
  //   };

  //   const getStyles = async () => {
  //     const styles = await getResource(project._links.styles.href);
  //     setSelectedStyles(styles._embedded?.styles);
  //     styleSelection.current = {
  //       existing: styles._embedded?.styles,
  //       new: [],
  //       toDelete: [],
  //     };
  //   };

  //   const getStyleImages = async () => {
  //     const getImageResult = await getResource(project._links.styleImages.href);

  //     const result = await Promise.all(
  //       getImageResult._embedded?.files.map(async (file) => {
  //         const blob = await getFile(file._links.self.href);
  //         const updatedFile = { ...file, blob };
  //         return updatedFile;
  //       }),
  //     );
  //     setUploadedStyleImages(result);
  //   };

  //   const getRoomImages = async () => {
  //     const getImageResult = await getResource(project._links.roomImages.href);

  //     const result = await Promise.all(
  //       getImageResult._embedded?.files.map(async (file) => {
  //         const blob = await getFile(file._links.self.href);
  //         const updatedFile = { ...file, blob };
  //         return updatedFile;
  //       }),
  //     );
  //     setPicturesCurrentRoom(result);
  //   };

  //   const handleClickRoom = (selectedRoom) => {
  //     if (room.type === selectedRoom.type) {
  //       setRoom({ ...room, type: null });
  //       return;
  //     }
  //     setRoom({ ...room, type: selectedRoom.type });
  //   };

  //   const handleClickColor = (color) => {
  //     handleClickMultiSelectableObject(
  //       color,
  //       colorSelection,
  //       selectedColors,
  //       setSelectedColors,
  //       'colorCode',
  //     );
  //   };

  //   const handleClickStyle = (style) => {
  //     handleClickMultiSelectableObject(
  //       style,
  //       styleSelection,
  //       selectedStyles,
  //       setSelectedStyles,
  //       'styleName',
  //     );
  //   };

  //   const handleClickBudget = (budget) => {
  //     if (selectedBudget.id === budget.id) {
  //       setSelectedBudget({});
  //       return;
  //     }
  //     setSelectedBudget(budget);
  //   };

  //   const handleClickSchedule = (schedule) => {
  //     if (selectedSchedule.id === schedule.id) {
  //       setSelectedSchedule({});
  //       return;
  //     }
  //     setSelectedSchedule(schedule);
  //   };

  //   const handleChangeUploadedStyleImages = (files) => {
  //     setUploadedStyleImages([...uploadedStyleImages, ...files]);
  //   };

  //   const handleChangePicturesCurrentRoom = (files) => {
  //     setPicturesCurrentRoom([...picturesCurrentRoom, ...files]);
  //   };

  //   const handleChangeProjectInput = (event) => {
  //     const { name, value } = event.target;
  //     setProject({ ...project, [name]: value });
  //   };

  //   const handleChangeRoomInput = (event) => {
  //     const { name, value } = event.target;
  //     setRoom({ ...room, [name]: value });
  //   };

  //   const handleChangePersonalDetails = (event) => {
  //     if (event.target.name === 'termsAccepted') {
  //       setClient({ ...client, termsAccepted: !client.termsAccepted });
  //       return;
  //     }
  //     const { name, value } = event.target;
  //     setClient({ ...client, [name]: value });
  //   };

  //   const handleClickPackage = (selectedDesignPackage) => {
  //     setDesignPackage({
  //       type: selectedDesignPackage.type,
  //       price: selectedDesignPackage.price,
  //       oldPrice: selectedDesignPackage.oldPrice,
  //     });
  //     setStep(step + 1);
  //   };

  return (
    <div className="page-with-sidebar">
      <Sidebar />
      <div className="layout-container">
        <h1> Project</h1>
        {project.created}
      </div>
    </div>
  );
}
