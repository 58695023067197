export async function createResource(token, uri, object) {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  try {
    const response = await fetch(uri, {
      method: 'POST',
      headers,
      body: JSON.stringify(object),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function getResource(token, uri) {
  const headers = {
    Accept: 'application/json',
  };
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  try {
    const response = await fetch(uri, {
      headers,
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function patchResource(token, uri, object) {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  try {
    const response = await fetch(uri, {
      method: 'PATCH',
      headers,
      body: JSON.stringify(object),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function deleteResource(token, uri) {
  const headers = {
    Accept: 'application/json',
  };
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  try {
    const response = await fetch(uri, {
      method: 'DELETE',
      headers,
    });
    if (response.status === 204) {
      console.log('resource deleted successfully');
    } else if (response.status === 404) {
      console.log('resource not found');
    } else {
      console.log('Error deleting resource');
    }
  } catch (error) {
    console.error(error);
  }
}
