import React from 'react';
import { ReactComponent as ArrowRight } from '../ui/assets/arrowRight.svg';
import { ReactComponent as ArrowRightBlack } from '../ui/assets/arrowRightBlack.svg';
import { ReactComponent as ArrowLeft } from '../ui/assets/arrowLeft.svg';
import { ReactComponent as CrossIcon } from '../ui/assets/cross.svg';
import { ReactComponent as UploadIcon } from '../ui/assets/upload.svg';

export default function Button({ caption, onClick, buttonType, icon, isDisabled }) {
  const allowedTypes = ['success', 'primary', 'secondary', 'link'];
  const buttonModifier = allowedTypes.includes(buttonType) ? `button--${buttonType}` : '';

  const retrieveButtonIcon = () => {
    switch (icon) {
      case 'arrowLeft':
        return <ArrowLeft />;
      case 'arrowRight':
        return <ArrowRight />;
      case 'arrowRightBlack':
        return <ArrowRightBlack />;
      case 'cross':
        return <CrossIcon />;
      case 'upload':
        return <UploadIcon />;
      default:
        return null;
    }
  };

  const disabledModifier = isDisabled ? 'button--disabled' : '';

  const handleOnClick = () => {
    if (isDisabled) return;
    onClick();
  };

  return (
    <div className={`button ${buttonModifier} ${disabledModifier}`} onClick={handleOnClick}>
      <div className="button__text">{caption}</div>
      <div className="button__icon">{retrieveButtonIcon()}</div>
    </div>
  );
}
