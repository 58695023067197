import React from 'react';

export default function Checkbox({
  name,
  checked,
  label,
  onChange,
  showValidation,
  validationMessage,
}) {
  return (
    <>
      <div className="checkbox">
        <input
          id={name}
          name={name}
          type="checkbox"
          checked={checked}
          onChange={(e) => {
            onChange(e);
          }}
          className={`checkbox__input ${showValidation ? 'checkbox__input--invalid' : ''}`}
        />
        <label
          className={`checkbox__label ${showValidation ? 'checkbox__label--invalid' : ''}`}
          htmlFor={name}
        >
          {label}
        </label>
      </div>
      {showValidation && <span className="checkbox__error">{validationMessage}</span>}
    </>
  );
}
