import bohemian from '../ui/assets/styles/bohemian.webp';
import bohemianAlternative from '../ui/assets/styles/bohemianAlternative.webp';
import coastal from '../ui/assets/styles/coastal.webp';
import coastalAlternative from '../ui/assets/styles/coastalAlternative.webp';
import cosmopolitan from '../ui/assets/styles/cosmopolitan.webp';
import cosmopolitanAlternative from '../ui/assets/styles/cosmopolitanAlternative.webp';
import industrial from '../ui/assets/styles/industrial.webp';
import industrialAlternative from '../ui/assets/styles/industrialAlternative.webp';
import minimalism from '../ui/assets/styles/minimalism.webp';
import minimalismAlternative from '../ui/assets/styles/minimalismAlternative.webp';
import modern from '../ui/assets/styles/modern.webp';
import modernAlternative from '../ui/assets/styles/modernAlternative.webp';
import rustic from '../ui/assets/styles/rustic.webp';
import rusticAlternative from '../ui/assets/styles/rusticAlternative.webp';
import scandinavian from '../ui/assets/styles/scandic.webp';
import scandinavianAlternative from '../ui/assets/styles/scandicAlternative.webp';
import traditional from '../ui/assets/styles/traditional.webp';
import traditionalAlternative from '../ui/assets/styles/traditionalAlternative.webp';

export default function getStyles() {
  return [
    { imageId: 'a1b2c3d4-5e6f-7g8h-9i0j-k1l2m3n4o5p6', styleName: 'BOHEMIAN', image: bohemian },
    {
      imageId: 'b2c3d4e5-6f7g-8h9i-0j1k-2l3m4n5o6p7',
      styleName: 'BOHEMIAN',
      image: bohemianAlternative,
    },
    { imageId: 'c3d4e5f6-7g8h-9i0j-1k2l-3m4n5o6p7q8', styleName: 'COASTAL', image: coastal },
    {
      imageId: 'd4e5f6g7-8h9i-0j1k-2l3m-4n5o6p7q8r9',
      styleName: 'COASTAL',
      image: coastalAlternative,
    },
    {
      imageId: 'e5f6g7h8-9i0j-1k2l-3m4n-5o6p7q8r9s0',
      styleName: 'COSMOPOLITAN',
      image: cosmopolitan,
    },
    {
      imageId: 'f6g7h8i9-0j1k-2l3m-4n5o-6p7q8r9s0t1',
      styleName: 'COSMOPOLITAN',
      image: cosmopolitanAlternative,
    },
    { imageId: 'g7h8i9j0-1k2l-3m4n-5o6p-7q8r9s0t1u2', styleName: 'INDUSTRIAL', image: industrial },
    {
      imageId: 'h8i9j0k1-2l3m-4n5o-6p7q-8r9s0t1u2v3',
      styleName: 'INDUSTRIAL',
      image: industrialAlternative,
    },
    { imageId: 'i9j0k1l2-3m4n-5o6p-7q8r-9s0t1u2v3w4', styleName: 'MINIMALISM', image: minimalism },
    {
      imageId: 'j0k1l2m3-4n5o-6p7q-8r9s-0t1u2v3w4x5',
      styleName: 'MINIMALISM',
      image: minimalismAlternative,
    },
    { imageId: 'k1l2m3n4-5o6p-7q8r-9s0t-1u2v3w4x5y6', styleName: 'MODERN', image: modern },
    {
      imageId: 'l2m3n4o5-6p7q-8r9s-0t1u-2v3w4x5y6z7',
      styleName: 'MODERN',
      image: modernAlternative,
    },
    { imageId: 'm3n4o5p6-7q8r-9s0t-1u2v-3w4x5y6z7a8', styleName: 'RUSTIC', image: rustic },
    {
      imageId: 'n4o5p6q7-8r9s-0t1u-2v3w-4x5y6z7a8b9',
      styleName: 'RUSTIC',
      image: rusticAlternative,
    },
    {
      imageId: 'o5p6q7r8-9s0t-1u2v-3w4x-5y6z7a8b9c0',
      styleName: 'SCANDINAVIAN',
      image: scandinavian,
    },
    {
      imageId: 'p6q7r8s9-0t1u-2v3w-4x5y-6z7a8b9c0d1',
      styleName: 'SCANDINAVIAN',
      image: scandinavianAlternative,
    },
    {
      imageId: 'q7r8s9t0-1u2v-3w4x-5y6z-7a8b9c0d1e2',
      styleName: 'TRADITIONAL',
      image: traditional,
    },
    {
      imageId: 'r8s9t0u1-2v3w-4x5y-6z7a-8b9c0d1e2f3',
      styleName: 'TRADITIONAL',
      image: traditionalAlternative,
    },
  ];
}
