import React from 'react';
import { useTranslation } from 'react-i18next';
import ButtonContainer from '../../components/buttonContainer';
import Input from '../../components/input';
import TextArea from '../../components/textarea';
import EnumerationOptionContainer from '../../components/enumerationOptionContainer';

export default function ProjectInformation({
  onClickNextStep,
  onClickPreviousStep,
  onChangeInputProject,
  onChangeInputRoom,
  selectedBudget,
  onClickBudget,
  selectedSchedule,
  onClickSchedule,
  project,
  rooms,
}) {
  const { t } = useTranslation();

  const getRoomTitle = (roomType) => {
    switch (roomType) {
      case 'BEDROOM':
        return t('roomOption.bedroom');
      case 'LIVING_ROOM':
        return t('roomOption.livingRoom');
      case 'KITCHEN':
        return t('roomOption.kitchen');
      case 'BATHROOM':
        return t('roomOption.bathroom');
      case 'DINING_ROOM':
        return t('roomOption.diningRoom');
      case 'KIDS_ROOM':
        return t('roomOption.kidsRoom');
      case 'ENTRYWAY':
        return t('roomOption.entryway');
      case 'OFFICE':
        return t('roomOption.office');
      case 'OUTDOOR':
        return t('roomOption.outdoor');
      case 'OTHER':
        return t('roomOption.other');
      default:
        return '';
    }
  };

  const handleChangeInputRoom = (event, room) => {
    onChangeInputRoom(event, room);
  };

  return (
    <div className="layout-container">
      <h3>{t('pages.projectDetails.title')}</h3>
      <div className="subtitle">{t('pages.projectDetails.subtitle')}</div>
      <div className="input-block">
        <h4>{t('pages.projectDetails.form.dimensions.title')}</h4>
        {rooms.map((room) => {
          return (
            <div key={room.type}>
              <h5 className="mb-05 mt-1">{getRoomTitle(room.type)}</h5>
              <div className="input-fields">
                <Input
                  key="length"
                  onChange={(event) => handleChangeInputRoom(event, room)}
                  label={t('pages.projectDetails.form.dimensions.answer.length.label')}
                  name="length"
                  value={room.length || ''}
                  placeholder={t('pages.projectDetails.form.dimensions.answer.length.placeholder')}
                  type="number"
                />
                <Input
                  key="width"
                  onChange={(event) => handleChangeInputRoom(event, room)}
                  label={t('pages.projectDetails.form.dimensions.answer.width.label')}
                  name="width"
                  value={room.width || ''}
                  placeholder={t('pages.projectDetails.form.dimensions.answer.width.placeholder')}
                  type="number"
                />
                <Input
                  key="height"
                  onChange={(event) => handleChangeInputRoom(event, room)}
                  label={t('pages.projectDetails.form.dimensions.answer.height.label')}
                  name="height"
                  value={room.height || ''}
                  placeholder={t('pages.projectDetails.form.dimensions.answer.height.placeholder')}
                  type="number"
                />
              </div>
            </div>
          );
        })}
      </div>
      <div className="input-block">
        <h4>{t('pages.projectDetails.form.budget.title')}</h4>
        <span>{t('pages.projectDetails.form.budget.subtitle')}</span>
        <div className="input-fields">
          <EnumerationOptionContainer
            name="budget"
            options={[
              { id: 'SMALL', caption: t('pages.projectDetails.form.budget.answer.small') },
              { id: 'MEDIUM', caption: t('pages.projectDetails.form.budget.answer.medium') },
              { id: 'LARGE', caption: t('pages.projectDetails.form.budget.answer.large') },
              {
                id: 'VERY_LARGE',
                caption: t('pages.projectDetails.form.budget.answer.extraLarge'),
              },
            ]}
            selectedOption={selectedBudget}
            onClick={onClickBudget}
          />
        </div>
      </div>
      <div className="input-block">
        <h4>{t('pages.projectDetails.form.schedule.title')}</h4>
        <div className="input-fields">
          <EnumerationOptionContainer
            name="schedule"
            options={[
              { id: 'FAST', caption: t('deadline.fast') },
              { id: 'NORMAL', caption: t('deadline.normal') },
              { id: 'SLOW', caption: t('deadline.slow') },
            ]}
            selectedOption={selectedSchedule}
            onClick={onClickSchedule}
          />
        </div>
      </div>
      <div className="input-block">
        <h4>{t('pages.projectDetails.form.descriptionOfProject.title')}</h4>
        <TextArea
          onChange={onChangeInputProject}
          name="description"
          value={project.description || ''}
          placeholder={t('pages.projectDetails.form.descriptionOfProject.placeholder')}
        />
      </div>
      <div className="button-container-wrapper">
        <ButtonContainer
          hasPreviousStep
          previousButtonOnClick={onClickPreviousStep}
          firstButtonCaption={t('pages.projectDetails.buttons.dontKnowButton')}
          firstButtonOnClick={onClickNextStep}
          secondButtonCaption={t('buttons.nextButton')}
          secondButtonOnClick={onClickNextStep}
          secondButtonType="primary"
          secondButtonIcon="arrowRight"
        />
      </div>
    </div>
  );
}
