import React from 'react';

export default function ColorLabel({ text, modifier }) {
  const retrieveStyle = () => {
    if (modifier === 'success') {
      return 'color-label--success';
    }
    if (modifier === 'warning') {
      return 'color-label--warning';
    }
    return '';
  };

  return <div className={`color-label ${retrieveStyle()}`}>{text}</div>;
}
