import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../components/button';
import Input from '../../components/input';
import isEmailValid from './validation/validation';

export default function ExitProject({ originalEmail, onClickSave, onClickCancel }) {
  const [showValidation, setShowValidation] = useState(false);
  const [email, setEmail] = useState(originalEmail);
  const { t } = useTranslation();

  const handleClickSave = () => {
    if (!isEmailValid(email)) {
      setShowValidation(true);
      return;
    }
    onClickSave(email);
  };

  const onChangeInput = (e) => {
    setEmail(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Escape') {
      onClickCancel();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <div className="exit-project">
      <h4>{`${t('pages.exitProject.title')}`}</h4>
      <div>{`${t('pages.exitProject.text')}'`}</div>
      <div className="input-block">
        <div className="input-fields">
          <Input
            key="email"
            onChange={onChangeInput}
            label={`${t('pages.exitProject.email.label')}*`}
            name="email"
            value={email || ''}
            type="text"
            showValidation={showValidation && !isEmailValid(email)}
            validationMessage={t('validation.email')}
          />
        </div>
      </div>
      <div className="exit-project-button-container">
        <Button
          caption={t('pages.exitProject.buttons.save')}
          onClick={handleClickSave}
          buttonType="primary"
        />
        <Button
          caption={t('pages.exitProject.buttons.cancel')}
          onClick={onClickCancel}
          buttonType="default"
        />
      </div>
    </div>
  );
}
