import React from 'react';

export default function Input({
  name,
  value,
  label,
  placeholder,
  type,
  onChange,
  showValidation,
  validationMessage,
}) {
  return (
    <div className="input">
      <label className="input__label" htmlFor={name}>
        {label}
      </label>
      <input
        id={name}
        name={name}
        type={type}
        value={value}
        placeholder={placeholder}
        onChange={(e) => onChange(e)}
        className={`input__field ${showValidation ? 'input__field--invalid' : ''}`}
      />
      {showValidation && <span className="input__error">{validationMessage}</span>}
    </div>
  );
}
