import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect } from 'react';

export default function LoginPage() {
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    loginWithRedirect({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: process.env.REACT_APP_AUTH0_SCOPE,
        redirect_uri: window.location.origin,
      },
    });
  }, []);

  return <div />;
}
