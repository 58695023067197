import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../components/button';
import ButtonContainer from '../../components/buttonContainer';
import Dropzone from '../../components/dropzone';
import StyleOptionContainer from '../../components/styleOptionsContainer';
import getStyles from '../../data/styles';

export default function StyleSelection({
  selectedStyles,
  onClick,
  onClickNextStep,
  onClickPreviousStep,
  onChangeFiles,
  files,
  onClickDelete,
  isButtonDisabled,
}) {
  const { t } = useTranslation();
  const styles = getStyles();

  return (
    <div className="layout-container">
      <div className="style-selection__header">
        <div>
          <h3>{t('pages.styleSelection.title')}</h3>
          <div className="subtitle">{t('pages.styleSelection.subtitle')}</div>
        </div>
        <Button
          caption={t('pages.styleSelection.buttons.uploadButton')}
          onClick={() => {
            document.getElementById('upload-container').scrollIntoView({ behavior: 'smooth' });
          }}
          buttonType="default"
          icon="upload"
        />
      </div>
      <div className="layout-container">
        <StyleOptionContainer styles={styles} selectedStyles={selectedStyles} onClick={onClick} />
      </div>
      <div id="upload-container">
        <div className="style-selection__header">
          <div>
            <h3>{t('pages.styleSelection.titleUpload')}</h3>
            <div className="subtitle">{t('pages.styleSelection.subtitleUpload')}</div>
          </div>
        </div>
        <Dropzone onChange={onChangeFiles} files={files} onClickDelete={onClickDelete} />
      </div>

      <div className="button-container-wrapper">
        <ButtonContainer
          hasPreviousStep
          previousButtonOnClick={onClickPreviousStep}
          firstButtonCaption={t('pages.styleSelection.buttons.dontKnowButton')}
          firstButtonOnClick={onClickNextStep}
          secondButtonCaption={t('buttons.nextButton')}
          secondButtonOnClick={onClickNextStep}
          secondButtonType="primary"
          secondButtonIcon="arrowRight"
          isDisabled={isButtonDisabled}
        />
      </div>
    </div>
  );
}
