export async function postFile(token, file) {
  const headers = {
    Accept: 'application/json',
  };
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  const formdata = new FormData();
  formdata.append('file', file);

  const requestOptions = {
    method: 'POST',
    headers,
    body: formdata,
  };

  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/files`, requestOptions);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function getFile(token, uri) {
  const headers = {};
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  try {
    const response = await fetch(`${uri}`, { headers });
    const blob = await response.blob();
    const documentURL = URL.createObjectURL(blob);
    return documentURL;
  } catch (error) {
    console.error(error);
    return null;
  }
}
