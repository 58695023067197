import React from 'react';
import { ReactComponent as Checked } from '../ui/assets/checked.svg';
import { ReactComponent as Unchecked } from '../ui/assets/unchecked.svg';

export default function ImageOption({ selected, onClick, children }) {
  return (
    <div
      className={`style-option ${selected ? 'style-option--selected' : ''}`}
      onClick={() => onClick()}
    >
      <div className="style-option__checkbox">
        {selected && <Checked />}
        {!selected && <Unchecked />}
      </div>
      <div className="style-option__image">{children}</div>
    </div>
  );
}
