import { createResource, deleteResource, patchResource } from '../../../api/resource';

export default async function commitMultiSelectableObjects(
  token,
  objectSelection,
  project,
  propertyName,
  codeProperty,
  additionalProperties,
) {
  const createObjectPromises = objectSelection.current.new.map((object) => {
    const requestObject = {};
    requestObject[codeProperty] = object[codeProperty];
    additionalProperties.forEach((property) => {
      requestObject[property] = object[property];
    });

    return createResource(
      token,
      `${process.env.REACT_APP_BACKEND_URL}/${propertyName}`,
      requestObject,
    );
  });

  const newObjects = await Promise.all([...createObjectPromises]);

  const filteredObjects = objectSelection.current.existing.filter((existingObject) => {
    return !objectSelection.current.toDelete.some(
      (toDeleteObject) => toDeleteObject[codeProperty] === existingObject[codeProperty],
    );
  });

  objectSelection.current.active = [...filteredObjects, ...newObjects];

  const requestObject = {
    [propertyName]: objectSelection.current.active.map((object) => object._links.self.href),
  };

  await patchResource(token, project._links.self.href, requestObject);

  const deleteObjectPromises = objectSelection.current.toDelete.map((object) => {
    return deleteResource(token, object._links.self.href);
  });
  await Promise.all([...deleteObjectPromises]);
  objectSelection.current.new = [];
  objectSelection.current.toDelete = [];
}
