import React from 'react';
import { useTranslation } from 'react-i18next';

function Banner() {
  const { t } = useTranslation();

  return <div className="banner">{t('banner.caption')}</div>;
}

export default Banner;
