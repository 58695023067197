import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Logo } from '../ui/assets/logo.svg';
import LanguageSwitcher from './languageSwitcher';
import Modal from './modal';
import Wizard from './wizard';
import ExitProject from '../pages/exit-project/exitProject';

export default function Navbar({ step, showWizard, email, onClickSave }) {
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();

  const handleClickCancel = () => {
    setShowModal(false);
  };

  return (
    <nav className="navbar">
      <div className="navbar__logo ">
        <Logo />
      </div>
      <div className="navbar__middle-content">{showWizard && <Wizard step={step} />}</div>
      <div className="navbar__end-content">
        <LanguageSwitcher />
        <div onClick={() => setShowModal(true)}>{`${t('navbar.exitButton')} X`}</div>
        {showModal &&
          createPortal(
            <Modal onClose={() => setShowModal(false)}>
              <ExitProject
                originalEmail={email}
                onClickSave={onClickSave}
                onClickCancel={handleClickCancel}
              />
            </Modal>,
            document.body,
          )}
      </div>
    </nav>
  );
}
