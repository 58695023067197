import React from 'react';
import { ReactComponent as HalfStar } from '../ui/assets/halfStar.svg';
import { ReactComponent as Star } from '../ui/assets/star.svg';

export default function StarRating({ rating }) {
  const stars = [];

  for (let i = 1; i <= 5; i += 1) {
    if (rating >= i) {
      stars.push(<Star key={i} />);
    } else if (rating >= i - 0.5) {
      stars.push(<HalfStar key={i} />);
    } else {
      stars.push(<span key={i} />);
    }
  }

  return <div className="star-rating">{stars}</div>;
}
