import React from 'react';

export default function Modal({ onClose, children }) {
  return (
    <div className="modal">
      <div className="modal__main-container">
        <div className="modal__main-container__exit-container" onClick={onClose}>
          <div className="modal__main-container__exit-container__button" onClick={onClose}>
            x
          </div>
        </div>
        <div className="modal__inner-container">
          <div className="modal__inner-container__content">{children}</div>
        </div>
      </div>
    </div>
  );
}
