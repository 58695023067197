import React from 'react';
import getRooms from '../data/rooms';
import ProjectCard from './projectCard';

export default function ProjectCardContainer({
  projects,
  onClick,
  statusLabel,
  dateCreatedLabel,
  deadLineLabel,
}) {
  const getRoomIcon = (project) => {
    const rooms = getRooms();
    const roomForProject = rooms.find((room) => room.type === project.room.type);
    return <roomForProject.icon />;
  };

  const getRoomName = (project) => {
    const rooms = getRooms();
    const roomForProject = rooms.find((room) => room.type === project.room.type);
    return roomForProject.name;
  };

  const renderCardComponents = () => {
    return projects.map((project) => {
      return (
        <ProjectCard
          key={project._links.self.href}
          onClick={() => onClick(project)}
          project={project}
          statusLabel={statusLabel}
          dateCreatedLabel={dateCreatedLabel}
          deadLineLabel={deadLineLabel}
        >
          <div className="project-card-container__room-image">{getRoomIcon(project)}</div>
          <div className="project-card-container__room-text">
            {`${getRoomName(project)} project`}
          </div>
        </ProjectCard>
      );
    });
  };

  return <div className="project-card-container">{renderCardComponents()}</div>;
}
