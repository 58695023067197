import i18n from '../i18n';
import { ReactComponent as roomBathroom } from '../ui/assets/roomBathroom.svg';
import { ReactComponent as roomBedroom } from '../ui/assets/roomBedroom.svg';
import { ReactComponent as roomDiningRoom } from '../ui/assets/roomDiningRoom.svg';
import { ReactComponent as roomEntryway } from '../ui/assets/roomEntryway.svg';
import { ReactComponent as roomKidsRoom } from '../ui/assets/roomKidsRoom.svg';
import { ReactComponent as roomKitchen } from '../ui/assets/roomKitchen.svg';
import { ReactComponent as roomLivingRoom } from '../ui/assets/roomLivingRoom.svg';
import { ReactComponent as roomOffice } from '../ui/assets/roomOffice.svg';
import { ReactComponent as roomOther } from '../ui/assets/roomOther.svg';
import { ReactComponent as roomOutdoor } from '../ui/assets/roomOutdoor.svg';

export default function getRooms() {
  const { t } = i18n;
  return [
    { type: 'BEDROOM', name: `${t('roomOption.bedroom')} `, icon: roomBedroom },
    {
      type: 'LIVING_ROOM',
      name: `${t('roomOption.livingRoom')} `,
      icon: roomLivingRoom,
    },
    { type: 'KITCHEN', name: `${t('roomOption.kitchen')} `, icon: roomKitchen },
    {
      type: 'BATHROOM',
      name: `${t('roomOption.bathroom')} `,
      icon: roomBathroom,
    },
    {
      type: 'DINING_ROOM',
      name: `${t('roomOption.diningRoom')} `,
      icon: roomDiningRoom,
    },
    {
      type: 'KIDS_ROOM',
      name: `${t('roomOption.kidsRoom')} `,
      icon: roomKidsRoom,
    },
    {
      type: 'ENTRYWAY',
      name: `${t('roomOption.entryway')} `,
      icon: roomEntryway,
    },
    { type: 'OFFICE', name: `${t('roomOption.office')} `, icon: roomOffice },
    { type: 'OUTDOOR', name: `${t('roomOption.outdoor')} `, icon: roomOutdoor },
    { type: 'OTHER', name: `${t('roomOption.other')} `, icon: roomOther },
  ];
}
